import { css } from '@emotion/react';

import { fonts, fluid, mq } from '../../../style-tokens';

const fontBase = css`
  font-size: ${fluid(1.3, 1.55)};
  line-height: 1.2;
`;

export const item = css`
  color: white;
  display: block;
  font-family: ${fonts.sans.family};
  font-weight: 500;
  letter-spacing: ${fluid(0.02, 0.035)};
  line-height: 1.25;
  padding: ${fluid(0.25, 0.5)} 0 ${fluid(0.25, 0.5)} ${fluid(3.5, 4)};
  position: relative;
  text-decoration: none;

  :hover > span:last-child > span:first-child,
  :focus > span:last-child > span:first-child {
    text-decoration: underline;
  }
`;

export const number = css`
  ${fontBase}

  left: 0;
  letter-spacing: 0.015rem;
  position: absolute;
  top: ${fluid(0.3, 0.55)};

  @media ${mq.tablet} {
    min-width: 1rem;
  }
`;

export const titleContainer = css`
  display: inline-block;
  font-size: 0;
  line-height: 0;
`;

export const title = css`
  ${fontBase}
`;

export const author = css`
  ${fontBase}

  color: black;
  display: block;
  flex-basis: 100%;
  margin-top: 0.15rem;
`;

export const iconInlineContainer = css`
  display: inline;
  margin-left: 0.5rem;
  position: relative;
  top: 0.05rem;

  > svg {
    bottom: ${fluid(-0.5, -0.5)};
    left: 0;
    position: absolute;
  }
`;

export const icon = css`
  ${fontBase}

  height: 2.25ch;
  order: 3;
  width: 2.25ch;
`;
