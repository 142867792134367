const path = require('path');
const slugify = require('slugify');

const cleanInterviewTitle = (title) => title.split('&shy;').join('');

const createInterviewSlug = (title) =>
  slugify(cleanInterviewTitle(title), {
    lower: true,
    strict: true,
    locale: 'de',
  });

const findAuthorByName = (authors, authorName) =>
  authors.find(({ frontmatter: { name } }) => name === authorName);

const createInterviews = async ({
  graphql,
  reporter,
  actions: { createPage },
}) => {
  const {
    errors,
    data: {
      authors: { nodes: authors },
      interviews: { nodes: interviews },
    },
  } = await graphql(`
    {
      interviews: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/interviews/.*.md/" } }
      ) {
        nodes {
          id
          frontmatter {
            title
            authors
          }
        }
      }

      authors: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/authors/.*.md/" } }
      ) {
        nodes {
          id
          frontmatter {
            name
          }
        }
      }
    }
  `);

  if (errors) {
    throw new Error('Error while running GraphQL query for HomePage.');
  }

  interviews.forEach(
    ({ id, frontmatter: { title, authors: interviewAuthors } }) => {
      const interviewAuthorIds = interviewAuthors
        .map((authorName) => {
          const author = findAuthorByName(authors, authorName);

          if (author) {
            return author.id;
          }

          return null;
        })
        .filter(Boolean);
      const slug = createInterviewSlug(title);

      reporter.info(`Interview: created ${slug}`);

      createPage({
        path: `/interviews/${slug}`,
        component: path.resolve(`src/templates/Interview.jsx`),
        context: {
          interviewID: id,
          authorIDs: interviewAuthorIds,
        },
      });
    }
  );
};

module.exports = {
  cleanInterviewTitle,
  createInterviewSlug,
  createInterviews,
};
