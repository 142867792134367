import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import React from 'react';

import GlassesIcon from '../../../../static/icons/glasses.svg';
import SpeakerIcon from '../../../../static/icons/speaker.svg';

import {
  item as itemStyle,
  number as numberStyle,
  author as authorStyle,
  titleContainer as titleContainerStyle,
  title as titleStyle,
  iconInlineContainer,
  icon as iconStyle,
} from './item.style';

import { createInterviewSlug } from '../../../../lib/create-interviews';

export const fragment = graphql`
  fragment MainMenuItem on MarkdownRemark {
    frontmatter {
      title
      number
      authors
      soundcloudLink: soundcloud_link
    }
  }
`;

const Item = (
  { frontmatter: { title, number, authors, soundcloudLink } },
  ...props
) => (
  <Link
    css={itemStyle}
    to={`/interviews/${createInterviewSlug(title)}`}
    {...props}
  >
    <span css={numberStyle}>#{number}</span>

    <span css={titleContainerStyle}>
      <span css={titleStyle} dangerouslySetInnerHTML={{ __html: title }} />
      <span css={iconInlineContainer}>
        {soundcloudLink ? (
          <SpeakerIcon css={iconStyle} />
        ) : (
          <GlassesIcon css={iconStyle} />
        )}
      </span>

      <span css={authorStyle}>{authors.join(' und ')}</span>
    </span>
  </Link>
);

export default Item;
