export const colors = {
  blue: '#0083ff',
};

export const fonts = {
  sans: {
    family: 'Theinhardt',
  },
};

const MQ_BREAKPOINTS = [
  ['phone', 500],
  ['tablet', 768],
  ['desktop', 1024],
];

export const mq = MQ_BREAKPOINTS.reduce((acc, [name, size]) => {
  acc[name] = `only screen and (min-width: ${size}px)`;
  return acc;
}, {});

export const fluid = (from, to, options = {}) => {
  const opts = {
    vpFrom: 320,
    vpTo: 1440,
    baseSize: 16,
    ...options,
  };

  return `calc(${from * opts.baseSize}px + (${to * opts.baseSize} - ${
    from * opts.baseSize
  }) * ((100vw - ${opts.vpFrom}px) / (${opts.vpTo} - ${opts.vpFrom})))`;
};

export const bodySpacing = fluid(0.925, 2.55);
