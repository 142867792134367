import { css } from '@emotion/react';

import { fluid, fonts } from '../../../../style-tokens';

export const list = css`
  list-style: none;
  margin-bottom: 0;
  margin-top: 3rem;
  padding-left: 0;

  > * + * {
    margin-top: ${fluid(0.5, 3)};
  }
`;

export const listSecondLevel = css`
  margin-top: 1rem;
`;

export const itemFirstLevel = css`
  color: currentColor;
  display: block;
  font-family: ${fonts.sans.family};
  font-size: ${fluid(2.08, 3)};
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: ${fluid(0.5, 2)};
  padding-top: ${fluid(0.5, 2)};
  text-decoration: none;
`;
