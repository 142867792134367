import { css } from '@emotion/react';

import { bodySpacing, colors, fluid, mq } from '../../../style-tokens';

const buttonReset = css`
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
`;

const button = css`
  position: absolute;
  right: ${fluid(1.75, 2.75)};
  top: ${fluid(1.5, 2.5)};
  z-index: 10;
`;

export const burgerButton = css`
  ${buttonReset}
  ${button}

  @media ${mq.tablet} {
    display: none;
  }
`;

export const modelCloseButton = css`
  ${buttonReset}
  ${button}
`;

export const icon = css`
  height: ${fluid(2, 3)};
  pointer-events: none;
  width: ${fluid(2, 3)};
`;

export const modal = {
  overlay: {
    zIndex: 200,
  },

  content: {
    backgroundColor: colors.blue,
    bottom: 0,
    border: 0,
    borderRadius: 0,
    color: 'white',
    left: 0,
    padding: bodySpacing,
    right: 0,
    top: 0,
  },
};
