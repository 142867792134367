import Link from 'gatsby-link';
import React from 'react';

import Item from '../../../MainMenu/Item';

import { list, listSecondLevel, itemFirstLevel } from './menu.style';

const BurgerMenuList = ({ items }) => (
  <>
    <ul css={list}>
      {items.map(([url, label, children]) => (
        <li key={`burger-menu-${label}`}>
          {url ? (
            <Link to={url} css={itemFirstLevel}>
              {label}
            </Link>
          ) : (
            <p css={itemFirstLevel}>{label}</p>
          )}

          {children && (
            <ul css={[list, listSecondLevel]}>
              {children.map((child) => (
                <li key={`burger-menu-2nd-${child.frontmatter.title}`}>
                  <Item {...child} />
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  </>
);

export default BurgerMenuList;
