import { css } from '@emotion/react';
import React, { useState } from 'react';
import Modal from 'react-modal';

import {
  burgerButton,
  icon as iconStyle,
  modal as modalStyle,
  modelCloseButton,
} from './burgerMenu.style';

import Menu from './Menu';

import BurgerIcon from '../../../../static/icons/burger.svg';
import CloseIcon from '../../../../static/icons/close.svg';

Modal.setAppElement(`#___gatsby`);

const BurgerMenu = ({ items, theme = 'black' }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        css={[
          burgerButton,
          css`
            color: ${theme};
            display: ${isOpen ? 'none' : 'block'};
          `,
        ]}
        onClick={() => setIsOpen(!isOpen)}
        ariaLabel="Menü anzeigen"
      >
        <BurgerIcon css={iconStyle} />
      </button>

      <Modal
        isOpen={isOpen}
        style={modalStyle}
        onRequestClose={() => setIsOpen(false)}
      >
        <button
          type="button"
          css={[
            modelCloseButton,
            css`
              color: white;
            `,
          ]}
          onClick={() => setIsOpen(false)}
          ariaLabel="Menü schließen"
        >
          <CloseIcon css={iconStyle} />
        </button>

        <Menu items={items} />
      </Modal>
    </>
  );
};

export default BurgerMenu;
