import { css } from '@emotion/react';

import { colors, fonts, bodySpacing, fluid, mq } from '../../style-tokens';

import fontTheinhardtRegularWoff from '../../../static/fonts/Theinhardt-Regular/Theinhardt-Regular-subset.woff';
import fontTheinhardtRegularWoff2 from '../../../static/fonts/Theinhardt-Regular/Theinhardt-Regular-subset.woff2';

import fontTheinhardtRegularItalicWoff from '../../../static/fonts/Theinhardt-Italic/Theinhardt-Italic-subset.woff';
import fontTheinhardtRegularItalicWoff2 from '../../../static/fonts/Theinhardt-Italic/Theinhardt-Italic-subset.woff2';

import fontTheinhardtMediumWoff from '../../../static/fonts/Theinhardt-Medium/Theinhardt-Medium-subset.woff';
import fontTheinhardtMediumWoff2 from '../../../static/fonts/Theinhardt-Medium/Theinhardt-Medium-subset.woff2';

import fontTheinhardtMediumItalicWoff from '../../../static/fonts/Theinhardt-Medium-Italic/Theinhardt-Medium-Italic-subset.woff';
import fontTheinhardtMediumItalicWoff2 from '../../../static/fonts/Theinhardt-Medium-Italic/Theinhardt-Medium-Italic-subset.woff2';

import fontTheinhardtHeavyWoff from '../../../static/fonts/Theinhardt-Heavy/Theinhardt-Heavy-subset.woff';
import fontTheinhardtHeavyWoff2 from '../../../static/fonts/Theinhardt-Heavy/Theinhardt-Heavy-subset.woff2';

import fontTheinhardtHeavyItalicWoff from '../../../static/fonts/Theinhardt-Heavy-Italic/Theinhardt-Heavy-Italic-subset.woff';
import fontTheinhardtHeavyItalicWoff2 from '../../../static/fonts/Theinhardt-Heavy-Italic/Theinhardt-Heavy-Italic-subset.woff2';

export const reset = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    padding: ${bodySpacing} 0;
  }

  html {
    height: -webkit-fill-available;
  }

  a {
    color: currentColor;
  }
`;

export const border = (themeColor = colors.blue) => css`
  body::before,
  body::after {
    background-color: ${themeColor};
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    height: ${bodySpacing};
  }

  body::before {
    top: 0;
  }

  body::after {
    bottom: 0;
  }

  body {
    background-color: ${themeColor};
    border-left: ${bodySpacing} solid ${themeColor};
    border-right: ${bodySpacing} solid ${themeColor};
  }
`;

export const logo = css`
  font-family: ${fonts.sans.family};
  font-size: ${fluid(2, 3.2)};
  font-weight: 900;
  left: ${fluid(1.75, 1.5)};
  position: absolute;
  text-decoration: none;
  top: ${fluid(1.5, 1.25)};
  z-index: 100;

  @media ${mq.tablet} {
    position: fixed;
  }

  @media (min-width: 2300px) {
    left: ${fluid(1.75, 2.25)};
    top: ${fluid(1.75, 2.25)};
  }
`;

export const fontFaces = css`
  @font-face {
    font-family: 'Theinhardt';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${fontTheinhardtRegularWoff2}) format('woff2'),
      url(${fontTheinhardtRegularWoff}) format('woff');
  }

  @font-face {
    font-family: 'Theinhardt';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(${fontTheinhardtRegularItalicWoff2}) format('woff2'),
      url(${fontTheinhardtRegularItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'Theinhardt';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${fontTheinhardtMediumWoff2}) format('woff2'),
      url(${fontTheinhardtMediumWoff}) format('woff');
  }

  @font-face {
    font-family: 'Theinhardt';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url(${fontTheinhardtMediumItalicWoff2}) format('woff2'),
      url(${fontTheinhardtMediumItalicWoff}) format('woff');
  }

  @font-face {
    font-family: 'Theinhardt';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${fontTheinhardtHeavyWoff2}) format('woff2'),
      url(${fontTheinhardtHeavyWoff}) format('woff');
  }

  @font-face {
    font-family: 'Theinhardt';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(${fontTheinhardtHeavyItalicWoff2}) format('woff2'),
      url(${fontTheinhardtHeavyItalicWoff}) format('woff');
  }
`;
