import { useTitleTemplate } from 'hooked-head';
import { Global, css } from '@emotion/react';
import { useStaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import React, { useContext } from 'react';

import Menu from './Menu';
import BurgerMenu from './BurgerMenu';

import Context from '../../context';

import { border, fontFaces, reset, logo } from './style';

const Layout = ({
  menuTheme,
  menuPadded,
  burgerMenuTheme,
  titleTemplate = true,
  showLogo = true,
  logoColor = 'white',
  children,
}) => {
  const {
    interviews: { nodes: interviews },
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }

      interviews: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/interviews/.*.md$/" } }
        sort: { fields: frontmatter___number, order: DESC }
      ) {
        nodes {
          ...MainMenuItem
        }
      }
    }
  `);

  if (titleTemplate) {
    useTitleTemplate(`%s | ${title}`);
  }

  const { isInterview, getInterviewColor } = useContext(Context);

  return (
    <>
      <Global
        styles={[
          reset,
          fontFaces,
          border(isInterview ? getInterviewColor() : undefined),
        ]}
      />

      {showLogo && (
        <Link
          to="/"
          css={[
            logo,
            css`
              color: ${logoColor};
            `,
          ]}
        >
          Nachgefragt!
        </Link>
      )}

      <Menu
        items={[['/interviews', 'Interviews']]}
        position="top"
        theme={menuTheme}
      />

      <BurgerMenu
        items={[
          ['/', 'Home'],
          [false, 'Interviews', interviews],
          ['/zur-reihe', 'Zur Reihe'],
          ['/impressum', 'Impressum & Datenschutz'],
        ]}
        theme={burgerMenuTheme}
      />

      <main>{children}</main>

      <Menu
        items={[
          ['/zur-reihe', 'Zur Reihe'],
          ['/impressum', 'Impressum & Datenschutz'],
        ]}
        paddedColor={menuPadded}
        theme={menuTheme}
      />
    </>
  );
};

export default Layout;
