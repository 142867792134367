import Link from 'gatsby-link';
import React from 'react';

import {
  container,
  list,
  listContainer,
  positionTop,
  positionBottom,
  item,
  link,
  linkWhite,
  padded,
} from './menu.style';

const Menu = ({
  items,
  position = 'bottom',
  theme = 'black',
  paddedColor = 'transparent',
  ...props
}) => (
  <div
    css={[container, position === 'top' ? positionTop : positionBottom]}
    {...props}
  >
    <div css={[listContainer, padded(paddedColor, position)]}>
      <ul css={list(position)}>
        {items.map(([url, label]) => (
          <li css={item}>
            <Link to={url} css={[link, theme === 'white' ? linkWhite : null]}>
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Menu;
