import { createContext } from 'react';

const ThemeContext = createContext({
  isInterview: false,
  getInterviewColor() {},
});

export const { Provider } = ThemeContext;

export default ThemeContext;
