import { css } from '@emotion/react';

import { bodySpacing, fluid, fonts, mq } from '../../../style-tokens';

export const container = css`
  display: none;
  left: ${bodySpacing};
  position: fixed;
  right: ${bodySpacing};
  z-index: 10;

  @media ${mq.tablet} {
    display: grid;
    grid-column-gap: ${fluid(2, 5)};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const listContainer = css`
  position: relative;
  width: 100%;

  @media ${mq.tablet} {
    grid-column: 2;
  }
`;

export const padded = (backgroundColor) => css`
  ::before {
    background-color: ${backgroundColor};
    bottom: ${fluid(0.35, 0.5)};
    content: '';
    height: ${fluid(1.25, 1.75)};
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`;

export const list = (position) => css`
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  position: relative;
  transform: translateX(${fluid(0.5, 1.15)})
    ${position === 'bottom' ? `translateY(${fluid(0.1, 0.3)})` : ''};
  z-index: 2;
`;

export const positionBottom = css`
  bottom: ${bodySpacing};
  transform: translateY(35%);
`;

export const positionTop = css`
  top: ${bodySpacing};
  transform: translateY(-50%);
`;

export const item = css`
  margin-left: ${fluid(0, 3.5)};
`;

export const link = css`
  color: black;
  font-family: ${fonts.sans.family};
  font-size: ${fluid(0.8, 1.55)};
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;

  :hover,
  :focus {
    text-decoration: underline;
  }
`;

export const linkWhite = css`
  color: white;
`;
